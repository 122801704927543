import React, { useRef, useLayoutEffect, useState } from "react"
import tw from "twin.macro"
import { isBrowser, isEDMode } from "@components/utils"

import arrowIcon from "@images/icons/icon-arrow-left-white.svg"

const ScrollChart = ({ children, withBg }) => {
  const [showScroll, setShowScroll] = useState("show")

  const container = useRef()
  const content = useRef()

  const handleResize = () => {
    const currentContainer = container.current
    const currentContainerWidth = currentContainer.getBoundingClientRect().width
    const currentContent = content.current.children[0]
    const currentContentWidth = currentContent.scrollWidth

    currentContentWidth >= currentContainerWidth
      ? setShowScroll("show")
      : setShowScroll("hide")
  }

  useLayoutEffect(() => {
    if (isBrowser) {
      if (isEDMode && window.location.search.indexOf("scroll-left") > -1) {
        const urlParams = new URLSearchParams(window.location.search)
        container.current.scrollLeft +=
          Number(urlParams.get("scroll-left")) || content.current.scrollWidth
      }
    }
    window.addEventListener("resize", handleResize)
    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div>
      {showScroll === "show" && (
        <p tw="text-[12px] text-white font-bold uppercase rounded-[60px] bg-studio py-1 px-3 w-fit tracking-wider flex items-center leading-normal md:hidden">
          <img src={arrowIcon} alt="" tw="mr-2.5" />
          Scroll to view chart
        </p>
      )}
      <div css={[tw`rounded-3xl py-6`, withBg && tw`bg-white-lilac`]}>
        {/* Scroll container on mobile */}
        <div
          id="scroll-content"
          tw="overflow-x-auto scrollbar-thin scrollbar-thumb-scrollbarThumb scrollbar-thumb-rounded-xl scrollbar-track-lilacTransparency scrollbar-track-rounded-xl md:(overflow-hidden)"
          ref={container}
        >
          <div ref={content}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ScrollChart
